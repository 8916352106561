@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

.main_container {
  width: 100vw;
  height: 100vh;
  padding: 32px 64px;
  font-family: 'Roboto Condensed', sans-serif;
}

.header {
  width: 100%;
}

.upperhand_logo {
  width: 195px;
  height: 80px;
  object-fit: cover;
}

.section {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 64px;
}

.info_container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 700px;
}

.span {
  font-weight: 700;
  color: #454952;
  font-size: 101px;
}

.title {
  font-weight: 700;
  color: #454952;
  font-size: 48px;
}

.information {
  font-weight: normal;
  font-size: 20px;
  color: #454952;
  line-height: 23.44px;
}

.maintenance_image {
  width: 850px;
  height: 567px;
  object-fit: cover;
}

@media screen and (max-width: 640px ) {
  .main_container {
    padding: 20px 32px 0;
  }

  .section {
    flex-direction: column;
    gap: 100px;
  }

  .info_container {
    width: 100%;
    gap: 0;
    padding-top: 32px;
  }

  .span {
    font-size: 88px;
  }

  .title {
    font-size: 32px;
  }

  .maintenance_image {
    width: 100%;
    height: 100%;
  }


}