.p-multiselect-label,
.p-multiselect-items-label {
    display: flex;
    gap: 6px;
}
  
.p-multiselect:not(.p-disabled):hover{
    border-color: #ced4da;
    box-shadow: none;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--color-custom);
    background: var(--color-custom);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{
    color: var(--color-custom);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
 
    box-shadow: inset 0 0 0 0.05rem var(--color-custom);
}

.p-inputtext:enabled:hover {
    border-color: var(--color-custom);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover{
    border-color: var(--color-custom);
    background: var(--color-custom);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover{
    border-color: var(--color-custom);
}

.p-multiselect-label.p-multiselect-items-label{
    padding-top: 4px;
    padding-bottom: 4px;
}

.p-multiselect:not(.p-disabled).p-focus{
    border-color: #ced4da;
    box-shadow: none;
}

.p-multiselect-panel{
    border: 1px solid #ced4da;;
}

.p-multiselect .p-multiselect-label.p-placeholder{
    font-size: 0.875rem;
    padding-top: 8px;
    padding-bottom: 8px;
}