.skFoldingCube {
	margin: 20px auto;
	width: 80px;
	height: 80px;
	position: relative;
	-webkit-transform: rotateZ(45deg);
	transform: rotateZ(45deg);
}

.skFoldingCube .skCube {
	float: left;
	width: 50%;
	height: 50%;
	position: relative;
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}
.skFoldingCube .skCube:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--color-custom);
	-webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
	animation: sk-foldCubeAngle 2.4s infinite linear both;
	-webkit-transform-origin: 100% 100%;
	-ms-transform-origin: 100% 100%;
	transform-origin: 100% 100%;
}
.skFoldingCube .skCube2 {
	-webkit-transform: scale(1.1) rotateZ(90deg);
	transform: scale(1.1) rotateZ(90deg);
}
.skFoldingCube .skCube3 {
	-webkit-transform: scale(1.1) rotateZ(180deg);
	transform: scale(1.1) rotateZ(180deg);
}
.skFoldingCube .skCube4 {
	-webkit-transform: scale(1.1) rotateZ(270deg);
	transform: scale(1.1) rotateZ(270deg);
}
.skFoldingCube .skCube2:before {
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
}
.skFoldingCube .skCube3:before {
	-webkit-animation-delay: 0.6s;
	animation-delay: 0.6s;
}
.skFoldingCube .skCube4:before {
	-webkit-animation-delay: 0.9s;
	animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
	0%,
	10% {
		-webkit-transform: perspective(140px) rotateX(-180deg);
		transform: perspective(140px) rotateX(-180deg);
		opacity: 0;
	}
	25%,
	75% {
		-webkit-transform: perspective(140px) rotateX(0deg);
		transform: perspective(140px) rotateX(0deg);
		opacity: 1;
	}
	90%,
	100% {
		-webkit-transform: perspective(140px) rotateY(180deg);
		transform: perspective(140px) rotateY(180deg);
		opacity: 0;
	}
}

@keyframes sk-foldCubeAngle {
	0%,
	10% {
		-webkit-transform: perspective(140px) rotateX(-180deg);
		transform: perspective(140px) rotateX(-180deg);
		opacity: 0;
	}
	25%,
	75% {
		-webkit-transform: perspective(140px) rotateX(0deg);
		transform: perspective(140px) rotateX(0deg);
		opacity: 1;
	}
	90%,
	100% {
		-webkit-transform: perspective(140px) rotateY(180deg);
		transform: perspective(140px) rotateY(180deg);
		opacity: 0;
	}
}
