.react-datepicker__day:hover {
  background-color: var(--color-custom);
  color: white;
}

.react-datepicker__day--selected {
  background-color: var(--color-custom);
  border-radius: 0.3rem;
  color: white;
}

.react-datepicker__day--selected:hover {
  background-color: var(--color-custom);
}
