.fadeOut {
	opacity: 1;
	transform: translate3d(0, 0, 0); /* ADDED - start x position as -50px */
	cursor: pointer;
	transition: 0.5s all ease-in-out;
}

.fadeOut-active {
	opacity: 0;
	transform: translate3d(-10px, 0, 0); /* ADDED - move x to 0 on hover */
}

.fadeIn {
	opacity: 0;
	transform: translate3d(0, 0, 0); /* ADDED - start x position as -50px */
	cursor: pointer;
	transition: 0.8s all ease-in-out;
	width: 0.1px;
	position: absolute;
}

.fadeIn-active {
	opacity: 1;
	transform: translate3d(-10px, 0, 0); /* ADDED - move x to 0 on hover */
	width: fit-content;
}