.main_container {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(170deg, #1D2027 50%, #37474F 50%);
    display: flex;
    align-items: center;
}

.info_container {
    display: flex;
    flex-direction: column;
    gap: 80px;
    width: 763px;
    height: 414px;
    padding-left: 80px;
}

.title {
    margin: 0;
    color: white;
    width: 70%;
    font-size: 40px;
    font-weight: bold;
}

.button_back {
    width: 330px;
    height: 56px;
    border: none;
    border-radius: 8px;
    background-color: #4AD669;
    text-align: center;
    color: white;
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;
}

.image_container {
    height: 100%;
    padding-top: 40px;
}

.notfound_img {
    height: 100%;
    object-fit: contain;
}

@media screen and (max-width: 450px) {
    .main_container {
        flex-direction: column;
        position: relative;
    }

    .info_container {
        width: 100%;
        padding: 40px 20px 0;
    }

    .title {
        width: 90%;
        font-size: 28px ;
    }

    .button_back {
        height: 50px;
        padding: 0;
        width: 90%;
        margin: 0 auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 10%;
    }

    .image_container {
        width: 90%;
        margin: -40% auto 0;
        padding-left: 16px;
    }

    .notfound_img {
        height: 350px;
    }
}