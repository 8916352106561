@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--color-custom: #4ad669;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@layer utilities {
	.hide-scroll-bar::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	.hide-scroll-bar {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}

	.show-scrollbar-x::-webkit-scrollbar {
		height: 2px;
	}

	.show-scrollbar-x::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
	}

	.show-scrollbar-x::-webkit-scrollbar-thumb {
		border-radius: 999px;
		background-color: rgba(9, 9, 9, 0.2);
		outline: 1px solid rgba(6, 6, 6, 0.2);
	}
}

::-webkit-scrollbar {
	width: 6px;
	height: 10px;
	border-radius: 9999px;
}

::-webkit-scrollbar-track {
	background: #c4c4c4;
	border-radius: 9999px;
}

::-webkit-scrollbar-thumb {
	background: var(--color-custom);
	border-radius: 9999px;
}

::-webkit-scrollbar-thumb:hover {
	background: var(--color-custom);
}
