.react-time-picker .react-time-picker__wrapper {
    height: 40px;
    border: 1px solid #D0D5DD;
    flex-direction: row;
    flex-grow: 0;
    background-color: white;
    font-family: 'Roboto Condensed';
    color: #6b7280;
}

.react-time-picker__wrapper{
    width: 100%;
    text-align: start;
    @media only screen and (max-width: 768px) {
        text-align: center;
    }
}

.react-time-picker__inputGroup {
    appearance: none;
    font-size: 14px;
    color: #929BB2;
    font-weight: 700;
    @media screen and  (max-width: 768px) {
        font-size: 16px;
    } 
}

.react-time-picker__clock {
    display: none;
}

.react-time-picker .react-time-picker__clock-button {
    padding: 4px;
}

.react-time-picker .react-time-picker__button:enabled {
    cursor: default;
}