
.fc {
  height: 100%;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: var(--color-custom);
  border-color: var(--color-custom);
  color: white;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: var(--color-custom);
  border-color: var(--color-custom);
  color: white;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-prev-button {
  background: var(--color-custom);
  border-color: var(--color-custom);
  color: white;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-button.fc-prev-button:enabled:hover {
  background: var(--color-custom);
  border-color: var(--color-custom);
  color: white;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-next-button {
  background: var(--color-custom);
  border-color: var(--color-custom);
  color: white;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-button.fc-next-button:enabled:hover {
  background: var(--color-custom);
  border-color: var(--color-custom);
  color: white;
}

.fc .fc-toolbar-title {
  font-size: 1.5em;
}

.fc.fc-theme-standard .fc-view-harness th {
  background: transparent;
  border-color: #dee2e6;
  color: #343a40;
}

.fc .fc-toolbar-title {
  text-align: center;
  font-size: 1rem;
}

@media (min-width: 427px) {
  .fc .fc-toolbar-title {
    text-align: center;
    font-size: 1.5rem;
  }
}
